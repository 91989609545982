'use client';

import Link from "next/link";
import { useRouter } from "next/navigation";
import useRouterWithLanguage from "@/utils/useRouterWithLanguage";


export default function Example({ data, title }: { data: any[], title: string }) {
    const router = useRouterWithLanguage();
    const redirect = (name: string) => {
        router.push(`/home/${name}`)
    }

    const handleRouter = (path: string) => {
        router.push(path);
    };

    return (
        <div className="bg-bc-gray py-8 sm:py-12">
            <div className="mx-auto px-4 lg:px-8">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8">
                    {/* Left Column */}
                    <div className="col-span-1">
                        <h4 className="font-reckless text-maroon">{title}</h4>
                    </div>

                    {/* Right Columns */}
                    <div className="mt-6 md:mt-0 col-span-1">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-8">
                            {data && data?.map((data, idx) => (
                                <h4 key={idx} onClick={() => handleRouter(data.href)} aria-label="products">
                                    <span key={idx} className="underline cursor-pointer underline-offset-4 decoration-2">{data?.label}</span>
                                </h4>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
