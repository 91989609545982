import startsWithPrefix from "@/utils/pathUtils";
import { useEffect, useRef } from "react";

export default function Example({ data, onlyImage = false }: { data: any, onlyImage?: boolean }) {
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const scrollContainer = scrollRef.current;

        if (!scrollContainer) return;

        let animationFrameId: number;

        const scroll = () => {
            if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
                // Reset scroll to the start when it reaches halfway
                scrollContainer.scrollTo({ left: 0, behavior: "auto" });
            } else {
                // Scroll the container by 1px per frame
                scrollContainer.scrollBy({ left: 2, behavior: "auto" });
            }

            animationFrameId = requestAnimationFrame(scroll);
        };

        scroll(); // Start scrolling

        return () => cancelAnimationFrame(animationFrameId); // Cleanup on unmount
    }, []);

    return (
        <div>
            {
                !onlyImage && <div className="bg-bc-gray px-4 sm:px-12 pb-16 pt-24 sm:pt-32">
                    <div className="mx-auto">
                        <div className="mx-auto lg:mx-0">
                            <h2>
                                {data?.title}
                            </h2>
                            <p className="mt-8">
                                {data?.description}
                            </p>
                        </div>
                    </div>
                </div>
            }

            <div className="bg-bc-gray py-12 border-t border-b border-line-gray">
                <div className="mx-auto px-4 lg:px-8">
                    <div
                        ref={scrollRef}
                        className="flex overflow-x-hidden space-x-20 md:space-x-24 scrollbar-hide"
                    >
                        {data?.partnerImages?.map((img: any, index: number) => (
                            <img
                                key={index}
                                alt={`Partnership ${index + 1}`}
                                src={`${startsWithPrefix(img)}`}
                                className="max-h-12 w-auto object-contain"
                                loading="lazy"
                            />
                        ))}
                        {/* Clone the images to create a seamless scrolling effect */}
                        {data?.partnerImages?.map((img: any, index: number) => (
                            <img
                                key={`clone-${index}`}
                                alt={`Partnership Clone ${index + 1}`}
                                src={`${startsWithPrefix(img)}`}
                                className="max-h-12 w-auto object-contain"
                                loading="lazy"
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
