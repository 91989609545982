"use client";

import React, { useState } from "react";
import Header from "@/components/sections/Header/page";
import HeroSection from "@/components/sections/HeroSection/page";
import About from "@/components/sections/About/page";
import Stats from "@/components/sections/Stats/page";
import StepsSection from "@/components/sections/StepsSection/page";
import WeSpeakYourLanguage from "@/components/sections/WeSpeakYourLanguage/page";
import Services from "@/components/sections/Services/page";
import Partnership from "@/components/sections/PartnerShip/page";
import OurWork from "@/components/sections/OurWork/page";
import ContactUs from "@/components/sections/ContactUs/page";
import Footer from "@/components/sections/Footer/page";
import { Description } from "@radix-ui/react-toast";
import { random } from "lodash";
import { randomInt } from "crypto";
import { lab } from "color";
import { useRouter } from "next/navigation";
import { useLayoutContext } from "@/useLayout";
import useRouterWithLanguage from "@/utils/useRouterWithLanguage";

const DocumentPreview = () => {
  const router = useRouterWithLanguage();
  const { lng } = useLayoutContext();

  const expertise = require(`@/data/${lng}/home/expertise.json`);
  const industries = require(`@/data/${lng}/home/industries.json`);
  const aiInsight = require(`@/data/${lng}/home/aiInsight.json`);
  const chatflow = require(`@/data/${lng}/home/chatflow.json`);
  const fuzionBooks = require(`@/data/${lng}/home/fuzionBooks.json`);
  // const rideX = require(`@/data/${lng}/home/rideX.json`);
  const xCart = require(`@/data/${lng}/home/xCart.json`);
  const stats = require(`@/data/${lng}/home/stats.json`);
  const steps = require(`@/data/${lng}/home/steps.json`);
  const weUnderstand = require(`@/data/${lng}/home/weUnderstand.json`);
  const partnerData = require(`@/data/${lng}/home/partnerShip.json`);
  const ourWork = require(`@/data/${lng}/home/ourWork.json`);

  return (
    <div className="overflow-y-hidden">
      <div className="h-screen overflow-y-auto bg-bc-gray dark:bg-gray-900">
        <div className="fixed top-0 left-0 w-full z-50">
          <Header />
        </div>
        <div className="pt-[55px] ml-auto max-w-auto">
          <HeroSection />
        </div>
        <div className="ml-auto max-w-auto md:max-w-[90%] px-4 lg:px-8 border-l border-b border-line-gray">
          <About />
        </div>
        <div className="ml-auto max-w-auto md:max-w-[90%] sm:px-6 lg:px-8 border-l border-b border-line-gray">
          <Stats data={stats?.content} title={stats?.title} />
        </div>
        <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-line-gray">
          <StepsSection steps={steps} />
        </div>
        <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-b border-line-gray">
          <WeSpeakYourLanguage data={weUnderstand} />
        </div>
        <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-b border-line-gray">
          <Services data={expertise?.content} title={expertise?.title} />
        </div>
        <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-b border-line-gray">
          <Services data={industries?.content} title={industries?.title} />
        </div>
        <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-b border-line-gray">
          <Partnership data={partnerData} />
        </div>
        <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-line-gray">
          <div className="bg-bc-gray px-4 sm:px-12 pb-12 pt-24 sm:pt-32 border-b border-line-gray">
            <div className="mx-auto">
              <div className="mx-auto lg:mx-0">
                <h2 className="text-left sm:text-center">{ourWork}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#e0e2eb] border-l border-line-gray">
          <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-line-gray md:h-32 bg-bc-gray"></div>
        </div>

        <div
          className="ml-auto max-w-auto md:max-w-[90%] border-l border-line-gray"
          onClick={() => {
            router.push(`/our-work/aiinsight`);
          }}
        >
          <OurWork
            data={aiInsight}
            key={`${aiInsight.title}_${randomInt}`}
            header={false}
          />
        </div>
        <div className="bg-[#b0ddbe] border-l border-line-gray">
          <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-line-gray md:h-32 bg-bc-gray"></div>
        </div>

        <div
          className="ml-auto max-w-auto md:max-w-[90%] border-l border-line-gray"
          onClick={() => {
            router.push(`/our-work/chat-flow`);
          }}
        >
          <OurWork
            data={chatflow}
            key={`${chatflow.title}_${randomInt}`}
            header={false}
          />
        </div>
        <div className="bg-[#d5e1f2] border-l border-line-gray">
          <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-line-gray md:h-32 bg-bc-gray"></div>
        </div>

        <div
          className="ml-auto max-w-auto md:max-w-[90%] border-l border-line-gray"
          onClick={() => {
            router.push(`/our-work/fuzionBooks`);
          }}
        >
          <OurWork
            data={fuzionBooks}
            key={`${fuzionBooks.title}_${randomInt}`}
            header={false}
          />
        </div>

        {/* Fleet X-bert */}

        {/* <div className="bg-[#DFD49C] border-l border-line-gray">
          <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-line-gray md:h-32 bg-bc-gray"></div>
        </div>
        <div
          className="ml-auto max-w-auto md:max-w-[90%] border-l border-line-gray"
          onClick={() => {
            router.push(`/our-work/fleet-xpert`);
          }}
        >
          <OurWork data={rideX} key={`${rideX.title}_${randomInt}`} header={false} />
        </div> */}

        <div className="bg-[#caecaa] border-l border-line-gray">
          <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-b border-line-gray md:h-32 bg-bc-gray"></div>
        </div>
        <div
          className="ml-auto max-w-auto md:max-w-[90%] border-l border-line-gray"
          onClick={() => {
            router.push(`/our-work/xCart`);
          }}
        >
          <OurWork
            data={xCart}
            key={`${xCart.title}_${randomInt}`}
            header={false}
          />
        </div>
        <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-b border-line-gray">
          <ContactUs />
        </div>
        <div className="bg-[#29292b]">
          <div className="ml-auto max-w-auto md:max-w-[90%] border-l border-b border-[#434345]">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentPreview;
