import { useLayoutContext } from "../../../useLayout";
import { useState, useEffect } from "react";

// Define expected data type
interface AboutData {
    title: string;
    subtitle: string;
}

export default function Example() {
    const { lng } = useLayoutContext();
    const [data, setData] = useState<AboutData | null>({
        title: "Loading...", // Default title to render immediately
        subtitle: "",
    });
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true); // Ensures hydration doesn't delay rendering
        async function fetchData() {
            try {
                const jsonData = (await import(`@/data/${lng}/home/about.json`)).default as AboutData;
                setData(jsonData);
            } catch (error) {
                console.error("Error loading data:", error);
            }
        }
        fetchData();
    }, [lng]);

    return (
        <div className="bg-bc-gray pb-12 pt-20 sm:pt-28">
            <div className="mx-auto">
                <div className="mx-auto lg:mx-0">
                    {/* LCP Optimized: Show Title Immediately */}
                    <h3 className="mt-2 text-3xl sm:text-4xl font-semibold leading-tight text-gray-900">
                        {isClient && data?.title}
                    </h3>

                    {/* Lazy Load Subtitle for Faster Mobile LCP */}
                    <p className="mt-6 text-pretty text-lg sm:text-xl font-light leading-relaxed">
                        {isClient && data?.subtitle}
                    </p>
                </div>
            </div>
        </div>
    );
}