import React, { useState, useEffect } from "react";
import { useLayoutContext } from '../../../useLayout';
import languageData from "@/data/languages.json";
import startsWithPrefix from '@/utils/pathUtils';

const StepsSection = ({ steps }: { steps: any[] }) => {

    const [activeId, setActiveId] = useState<number | null>(1);
    const { lng } = useLayoutContext();

    let [isArabic, setIsArabic] = useState(false);

    useEffect(() => {
        if (lng && lng === languageData?.languages[1]?.value) {
            setIsArabic(true);
        } else {
            setIsArabic(false);
        }
    }, [lng, languageData]);

    return (
        <div className="bg-bc-gray">
            {/* Image Section */}
            {/* <div className="relative bg-bc-gray h-[300px] md:h-[400px] lg:h-[500px]">
                <div className="absolute inset-0 flex">
                    <img
                        src="/ImagesNew/how we work (4).svg"
                        alt="Left Image"
                        className="w-full h-full object-cover aspect-[2/1]"
                    />
                </div>
            </div> */}

            <div className="relative bg-bc-gray w-full aspect-[5/2]">
                <img
                    src={startsWithPrefix(`home/${isArabic ? "how_we_work_arabic.svg" : "how we work (4).svg"}`)}
                    alt="Left Image"
                    className="absolute inset-0 w-full h-full object-cover"
                />
            </div>

            {/* Steps Section */}
            <div className="">
                <div className="mx-auto flex border-t border-b border-gray-300">
                    {steps.map((step) => (
                        <div
                            key={step.id}
                            onMouseEnter={() => setActiveId(step.id)}
                            onMouseLeave={() => setActiveId(1)}
                            className={`flex-1 hover:flex-[2] items-start justify-start p-2 md:p-6 border-r last:border-r-0 border-gray-300 transition-all duration-500 ease-in-out min-h-[200px] bg-bc-gray`}
                        >
                            <div className="flex flex-col flex-1 h-[195px]">
                                <h4 className="text-[12px] md:text-[22px] font-medium text-gray-900 !leading-[16px] md:!leading-7">
                                    {step.title}
                                </h4>
                                <div
                                    className={`transition-all duration-500 ease-in-out overflow-hidden ${activeId === step.id ? 'max-h-[192px] opacity-100' : 'max-h-0 opacity-0'
                                        }`}
                                    style={{
                                        transform: activeId === step.id ? 'scaleY(1)' : 'scaleY(0)',
                                        transformOrigin: 'top',
                                        padding: activeId === step.id ? '1rem 0' : '0',
                                    }}
                                >
                                    <p className="mt-4 md:mt-8 text-[11px] md:text-xl font-light !leading-[16px] md:!leading-7 text-gray-700">
                                        {step.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default StepsSection;
