import { useState, useEffect, useRef } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Progress } from "@/components/ui/progress";
import Link from 'next/link';
import { motion } from 'framer-motion';
import { useRouter, useSearchParams } from 'next/navigation';
import { Pencil, Menu, X, Loader2, ArrowDown } from 'lucide-react';
import HtmlRenderer from '@/components/HtmlRenderer';
import startsWithPrefix from '@/utils/pathUtils';
import Header from '@/components/sections/Header/page';
import { cn } from '@/lib/utils';
import useRouterWithLanguage from "@/utils/useRouterWithLanguage";
import { useLayoutContext } from '../../../useLayout';


const pageName = 'HeroSection';

const HeroSection = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isHeroInView, setIsHeroInView] = useState(false); // Track HeroSection visibility
    const heroRef = useRef(null);
    // const data = handler();
    const { lng } = useLayoutContext();
    const data = require(`@/data/${lng}/home/hero-section.json`);

    const router = useRouterWithLanguage();
    const searchParams = useSearchParams();
    const userId = searchParams.get('user_id');
    const isFromView = searchParams.get('preview');
    const showEdit = searchParams.get('showEdit');
    const isEdit = showEdit === 'true' ? true : false;
    const itemRefs = useRef<(HTMLSpanElement | null)[]>([]);
    const [isHeroVisible, setIsHeroVisible] = useState(false); // Visibility state
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Check screen width on client side
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Run on mount and add resize listener
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsHeroVisible(entry.isIntersecting);
            },
            { threshold: 0.5 } // Trigger when 50% of the component is visible
        );

        if (heroRef.current) observer.observe(heroRef.current);

        return () => {
            if (heroRef.current) observer.unobserve(heroRef.current);
        };
    }, []);

    useEffect(() => {
        if (isHeroVisible && itemRefs.current[currentSlide]) {
            itemRefs.current[currentSlide]?.scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "nearest",
            });
        }
    }, [currentSlide, isHeroVisible]);
    useEffect(() => {
        if (data?.bannerData?.length > 1) {
            const slideInterval = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % data.bannerData.length);
                setProgress(0); // Reset progress on slide change
            }, 7000); // Change slide every 7 seconds
    
            return () => clearInterval(slideInterval);
        }
    }, [data?.bannerData?.length]);
    
    useEffect(() => {
        const progressInterval = setInterval(() => {
            setProgress((prev) => (prev >= 100 ? 0 : prev + (100 / 70))); // Complete progress in 7 seconds
        }, 100);
    
        return () => clearInterval(progressInterval);
    }, []);
    // Update slide every 7 seconds
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setProgress((prevProgress) => {
    //             if (prevProgress >= 100) {
    //                 setCurrentSlide((prevSlide) => (prevSlide + 1) % (data?.bannerData?.length || 1));
    //                 return 0;
    //             }
    //             return prevProgress + 1;
    //         });
    //     }, 70);

    //     return () => clearInterval(interval);
    // }, [data?.bannerData?.length]);
   
    

    // Observer to toggle header visibility based on HeroSection visibility
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsHeroInView(entry.isIntersecting); // Toggle header fixed state based on visibility
            },
            { threshold: 0.5 }
        );

        if (heroRef.current) observer.observe(heroRef.current);

        return () => {
            if (heroRef.current) observer.unobserve(heroRef.current);
        };
    }, []);

    const handleViewPage = () => {
        router.push(`/preview?page=${pageName}&preview=true&user_id=${userId}&showEdit=false`);
    };

    return (
        <div
            ref={heroRef}
            className="relative pt-8 md:pt-0 bg-bc-gray h-auto sm:h-3/5 md:h-4/5 border-b border-line-gray"
            style={{
                // backgroundImage: cn(`
                // linear-gradient(to bottom right, 
                // rgba(0, 0, 0, 1) 0%,       /* Fully opaque black */
                // rgba(0, 0, 0, 0.85) 20%,  /* Slightly less opaque for a dense left side */
                // rgba(0, 0, 0, 0.5) 50%,   /* Smooth transition towards the center */
                // rgba(0, 0, 0, 0) 100%     /* Fully transparent */
                // ),
                // url(${data ? startsWithPrefix(data?.bannerData[currentSlide]?.banner_image[0]) : ""})`),

                // backgroundImage: !isMobile ? cn(`                   
                //     url('/ImagesNew/banner (4).svg')`) : "",
                // backgroundSize: 'cover',
                // backgroundPosition: 'center',
                // backgroundRepeat: 'no-repeat',
                // transition: 'background-image 1s ease-in-out',
            }}
        >

            {isEdit && (
                <div className='absolute right-4 top-20 z-50'>
                    <Button variant='outline' className='text-white' onClick={handleViewPage}><Pencil /></Button>
                </div>
            )}

            {/* Pass `isHeroInView` as a prop to the Header */}
            {/* <Header isFixed={isHeroInView} /> */}

            {/* Hero Content */}
            {/* <div className="relative"> */}
            <div className="relative px-4 md:px-24 mx-auto">
                <div className='grid lg:grid-cols-2 items-center'>
                    <div className="w-full min-h-[320px] lg:min-h-[520px] xl:min-h-[360px] 2xl:min-h-[440px]">
                        <motion.div
                            key={currentSlide}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.8 }}
                        >
                            {/* <motion.p
                            className="tracking-tighter"
                            initial={{ opacity: 0, y: 100 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -100 }}
                            transition={{ duration: 0.8 }}
                        >
                            <h1
                                className="w-full md:w-[57%] 2xl:w-[40%] font-semibold md:font-semibold text-3xl md:text-6xl !tracking-[3.2px] leading-[45px] md:leading-[75px]"
                            >
                                {data ? data?.bannerData[currentSlide]?.title : ""}
                            </h1>
                        </motion.p> */}
                            <motion.div
                                className="tracking-tighter"
                                initial={{ opacity: 0, y: 100 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -100 }}
                                transition={{ duration: 0.8 }}
                            >
                                <h1
                                    // className="w-full md:w-[58%] 2xl:w-[44%] font-semibold text-[36px] md:text-[76px] leading-[36px] md:leading-[80px]"
                                    className="w-full font-semibold text-[36px] md:text-[76px] leading-[36px] md:leading-[80px]"
                                >
                                    {data ? data?.bannerData[currentSlide]?.title : ""}
                                </h1>
                            </motion.div>

                            <motion.div
                                className="mt-[16px] mb-6 flex items-center gap-4 items-end"
                                initial={{ opacity: 0, x: -100 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 100 }}
                                transition={{ duration: 0.5 }}
                            >
                                {/* <p className="w-full md:w-[57%] 2xl:w-[40%] text-md lg:text-xl font-light md:leading-7" */}
                                <p className="w-full text-md lg:text-xl font-light md:leading-7"
                                // style={{
                                //     letterSpacing: "1.5px"
                                // }}
                                >
                                    {data ? data?.bannerData[currentSlide]?.description : ""}
                                </p>
                            </motion.div>
                        </motion.div>

                        <div className="relative flex w-full xl:w-1/2 h-[40px] md:h-[75px] items-center space-x-2 mt-[32px]">
                            <Button type="submit" onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                onClick={() => window.open(process.env.NEXT_PUBLIC_CALENDLY_URL, '_blank')}
                                className='absolute right-0 text-[20px] md:text-[26px] h-full font-normal bg-orange text-black w-full justify-between p-8 hover:bg-maroon hover:text-white'>
                                {data?.buttonTitle} <img src={
                                    isHovered
                                        ? startsWithPrefix("header/arrow_white.svg")
                                        : startsWithPrefix("home/arrow.svg")
                                } alt="Right Up Arrow" className='md:text-[1.0rem] font-[400] !tracking-[0.8px]' /></Button>
                        </div>
                    </div>
                    <div className='hidden lg:flex justify-end items-start'>
                        <img
                            src={`${startsWithPrefix(
                                data ? data?.bannerData[currentSlide]?.banner_image : `Home_Page_Images/home-hero.svg`
                            )}`}
                            alt="no_image"
                            className='w-[100%] max-h-[35rem]' />

                    </div>
                </div>


                <div className="!hidden relative pb-4 md:pt-10 flex flex-wrap xl:!grid grid-cols-2 items-end gap-4 lg:grid-cols-4">
                    {data?.bannerData?.map((label: any, index: number) => (
                        <span
                            key={label?.subTitle}
                            onClick={() => setCurrentSlide(index)}
                            className={`flex flex-col gap-4 cursor-pointer ${index === 0 ? 'items-start' : index === ((data?.bannerData?.length - 1) || 1) ? 'items-end' : 'items-center'
                                } group ${index === 1 ? "lg:pr-[70px] " : ""} `}
                        >

                            <span className={`grid grid-cols-1 gap-2 text-[12px] md:text-[16px] ${currentSlide === index ? "font-normal" : "font-light opacity-60"}`}>
                                {currentSlide === index && <Progress value={progress} className="w-[100%] h-[5px] bg-transparent rounded-none" />}
                                {label?.subTitle}
                            </span>
                        </span>
                    ))}
                </div>

                <div className="relative">
                    {/* Horizontal Scroll Section */}
                    <div className="xl:hidden relative pt-8 md:pt-12 2xl:pt-12 pb-4 overflow-x-auto scroll-snap-x grid grid-cols-[repeat(auto-fit,minmax(50%,1fr))] items-center gap-4 hide-scrollbar">
                        <div className="flex items-end gap-16 md:gap-40 w-max">
                            {data?.bannerData?.map((label: any, index: number) => (
                                <span
                                    key={label?.subTitle}
                                    onClick={() => setCurrentSlide(index)}
                                    ref={(el) => {
                                        itemRefs.current[index] = el;
                                    }}
                                    className={`flex flex-col gap-4 cursor-pointer ${index === 0
                                        ? "items-start"
                                        : index === (data?.bannerData?.length - 1 || 1)
                                            ? "items-end"
                                            : "items-center"
                                        } group ${index === 1 ? "lg:pr-[70px]" : ""}`}
                                >
                                    <span
                                        className={`grid grid-cols-1 gap-2 text-[14px] !tracking-[0.5px] leading-8 font-light ${currentSlide === index ? "" : "opacity-60"
                                            }`}
                                    >
                                        {currentSlide === index && (
                                            <Progress
                                                value={50} // Replace with `progress` or your dynamic value
                                                className="w-[100%] h-[5px] bg-transparent rounded-none"
                                            />
                                        )}
                                        {label?.subTitle}
                                    </span>
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

export default HeroSection;
